/*
 * Global Header Styling
 */

.masthead {
    height: 250px;
    h1 {
        color: white;
        font-size: 60px;
    }
}

.navbar {
    @media (min-width: @screen-sm) {
        border: none;
    }
    .navbar-brand {
        font-family: @font-family-sans-serif;;
    }
    .navbar-nav {
        li a {
            font-family: @font-family-sans-serif;
            font-size: 14px;
            text-transform: uppercase;
            @media (min-width: @screen-sm) and (max-width: @screen-sm-max) {
                font-size: 13px;
                padding-left: 10px;
                padding-right: 10px;
            }
        }
    }
}

@media (min-width: @screen-sm) {
    #primary-nav {
        display: flex !important;
        justify-content: center;
    }
}
