/*
 * Global Styles
 */

.page-header {
	margin-top: 0;
}
.subhead {
	font-family: @font-family-serif;
	line-height: 1.1;
	font-style: italic;
	font-size: 26px;
	color: @gray;
	margin-bottom: 1.25em;
}

blockquote {
	border-left-width: 1px;
	color: @gray-base;
}