/*
 * Thubnail Grid
 */

@thumb-padding: 8px;
.thumb-grid {
    display: flex;
    flex-wrap: wrap;
    .thumb {
        background: #fff;
        border: 1px solid @panel-default-border;
        padding: @thumb-padding;
        flex: 0 1 auto;
        width: 100%;
        margin-bottom: 20px;
        &:focus,
        &:hover,
        &:active {
            text-decoration: none;
            .title {
                color: @link-hover-color;
            }
        }
        @media (min-width: 640px) {
            margin-left: 20px;
            width: ~"calc(50% - 20px)";
        }
        @media (min-width: @screen-sm) {
            width: ~"calc(33% - 20px)";
        }
        @media (min-width: @screen-md) {
            width: ~"calc(25% - 20px)";
        }
    }
    .title {
        .truncate();
        font-family: @font-family-sans-serif;
        ;
        font-size: 12px;
        color: @gray-light;
        text-align: center;
    }
    img {
        margin: @thumb-padding 0;
    }
    .detail {
        margin-top: @thumb-padding;
        font-family: @font-family-serif;
        font-style: italic;
        font-size: 12px;
        text-align: center;
        color: @gray-light;
    }
}

// .thumb-grid {
//  .clearfix();
//     margin-left: -20px;
//     .thumb {
//         background: #fff;
//         border: 1px solid @panel-default-border;
//         padding: 8px;
//         float: left;
//         width: 150px;
//         margin-left: 20px;
//         margin-bottom: 20px;
//     }
//     .title {
//         .truncate();
//         font-size: 10px;
//         color: @gray-light;
//         text-align: center;
//     }
//     img {
//         margin: 8px 0;
//     }
//     .counter {
//         margin-top: 8px;
//         font-size: 12px;
//         text-align: center;
//         color: @gray-light;
//     }
//     .index {
//         color: @gray;
//     }
// }
